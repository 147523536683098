<template>
  <v-container class="fill-height">
    <v-row align="center"
           justify="center">
      <ess-card width="300"
                class="mx-auto elevation-10">
        <v-card-text>
          <span class="ess-font-text-h2 white--text">Logging out...</span>
          <v-progress-linear indeterminate
                             color="white"
                             class="mt-5" />
        </v-card-text>
      </ess-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  mounted() {
    setTimeout(async () => {
      this.logout();
    }, 2000);
  },

  methods: {
    ...mapActions('session', ['logout']),
  },
};
</script>

<style scoped>
  .rounded {
    border-radius: 10px !important;
    padding: 5px;
  }

  .rounded-dialog {
    border-radius: 10px !important;
  }
</style>
